<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmReason')" data-vv-scope="frmReason" novalidate="novalidate">
      <div class="row justify-content-md-center pt-2 px-2">

        <!-- Card Esquerda -->
        <div class="col-md-12 px-2">
          <div class="card card-fullheight">
            <div class="card-body p-2">
              <h5 class="box-title m-3">{{ $t('valuation-reason.new') }}</h5>

              <div class="row custom-row">

                <!--  Nome  -->
                <div class="col-md-6 form-group px-2 ">
                  <label class="small grey-label mb-0">Nome</label>
                  <custom-input
                    v-model="reason.nome"
                    name="reason.nome"
                    type="text"
                    rootClassName="md-form my-0"
                    inputClassName="md-form-control text-left"
                    v-validate="{ required: true }"
                    :error="errors.first('reason.nome')">
                  </custom-input>
                </div>

                <!--  Tipo  -->
                <div class="col-md-6 form-group px-2">
                  <label class="small grey-label mb-0">Tipo</label>
                  <v-select class="grey-field" name="reason.tipo" label="nome" :clearable="false"
                    :searchable="true" v-model="reason.tipo" :options="typeList"
                    @search="typeFetchOptions"
                    v-validate="{ required: true }">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um tipo
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum tipo encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('reason.tipo')" class="royalc-error-field">{{ errors.first('reason.tipo') }}</small>
                  </transition>
                </div>

                <!-- Descrição -->
                <div class="col-md-12 form-group px-2 mb-2">
                  <label class="small grey-label mb-0">Descricao</label>
                  <custom-textarea
                    v-model="reason.descricao"
                    name="reason.descricao"
                    type="text"
                    rootClassName="md-form my-0"
                    inputClassName="md-form-control mt-0"
                    :rows="3"
                    v-validate="{ required: false }"
                    :error="errors.first('reason.descricao')">
                  </custom-textarea>
                </div>

                <!--  Peso Padrão  -->
                <div class="col-lg-4 form-group px-2 ">
                  <label class="small grey-label mb-0">Peso Padrão
                    <small class="text-primary" v-if="!reason.tipo"></small>
                    <small class="text-primary" v-else-if="reason.tipo.positivo"> (positivo)</small>
                    <small class="text-danger" v-else> (negativo)</small> </label>
                  <input name="reason.peso_padrao" type="number" min="0" step="1" class="md-form-control text-center"
                    v-model="reason.peso_padrao" @blur="onLostFocus()"
                    :error="submitted ? errors.first('reason.peso_padrao') : ''"
                    v-validate="{ required: true }">
                  <transition name="slide" mode="">
                    <small v-if="errors.first('reason.peso_padrao')"
                      class="royalc-error-field">{{ errors.first('reason.peso_padrao') }}</small>
                  </transition>
                </div>

                <!--  Possui valor  -->
                <div class="col-lg-4 form-group px-2 ">
                  <label class="ui-switch no-margin small switch-solid switch-warning pl-2 mt-3 mr-2">
                    <input id="possui_valor" value="1" type="checkbox"
                      ref="possui_valor" v-model="reason.possui_valor"
                      :checked="reason.possui_valor"><span> </span>
                  </label>
                  <label class="small grey-label mb-0 pb-2">Possui valor</label>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12 mb-5">
          <button type="button" class="btn btn-sm btn-outline-light pull-right ml-2"
            @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm pull-right"
            @click="onCancel">Cancelar</button>
          <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right"
            @click="onShow">Console</button> -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
// import DualSwitch from '../../components/Forms/DualSwitch.vue'
// import SearchBarQuantity from '../Proposal/SearchBarQuantity.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
// import CustomInputDate from '@/components/Forms/CustomInputDate.vue'
// // import ArmadorField from '@/components/Forms/ArmadorField'
import CustomInput from '@/components/Forms/CustomInput.vue'
// import StatusSelector from './StatusSelector.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import ValuationReasonService from '@/services/ValuationReasonService'

export default {
  name: 'ReasonForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('valuation-reason.new') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      isEdit: false,
      disabled: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      reason: {
        nome: null,
        descricao: null,
        tipo: null,
        peso_padrao: null,
        possui_valor: false
      },
      typeList: [],
      showProcessingMessage: false,
      delay: 400
    }
  },
  components: {
    Loading,
    vSelect,
    CustomTextarea,
    CustomInput
    // DualSwitch
  },
  // Apply filters to local filter
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'ValuationReasonEdit'
    let id = this.$route.params.id

    let filters = {
      'searchTerm': '',
      'per_page': 500,
      'includeAlan': false
    }

    ValuationReasonService.getReasonTypeOptions(filters).then(res => {
      this.typeList = res.data.data
    })

    if (id) {
      ValuationReasonService.get(id).then(res => {
        let data = res.data.data
        let edit = this.reason

        edit.id = id
        edit.nome = data.nome
        edit.descricao = data.descricao
        edit.tipo = data.tipo
        edit.peso_padrao = data.peso_padrao
        edit.possui_valor = data.possui_valor

        this.reason = edit
      })
    }

    _this.isLoading = false
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onLostFocus () {
      if (this.reason.peso_padrao < 0) {
        this.reason.peso_padrao = 0
      }
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_Reason: object.id } }).href
    },
    /**
     * Lista de opções
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    typeFetchOptions (search, loading) {
      if (search.length > 0) {
        // let me = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)
          let filters = {
            'searchTerm': search,
            'includeAlan': true
          }

          ValuationReasonService.getReasonTypeOptions(filters).then(res => {
            this.typeList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'ValuationReasonIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          if (this.isEdit) {
            ValuationReasonService.put(this.reason.id, this.reason).then(response => {
              _this.$router.push({ name: 'ValuationReasonIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            ValuationReasonService.post(this.reason).then(response => {
              _this.$router.push({ name: 'ValuationReasonIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          }
        }
      })
    },
    onShow () {
    },
    isset (variable) {
      return variable !== 'undefined' && variable != null && variable !== ''
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
